import React from "react"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import PageTitle from "../../components/page-title"
import Constrain from "../../components/constrain"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import AudioPlayer from "../../components/audio-player"

const Katalog6 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Rainer Maria Rilke: An Hölderlin" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Rainer Maria Rilke: An Hölderlin",
                link: "/k/6",
              },
            ]}
          />
          <PageTitle>Rainer Maria Rilke: An Hölderlin</PageTitle>
          <Constrain>
            <AudioPlayer src="katalog/06-rainer-maria-rilke" />
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog6
